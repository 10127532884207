import React, { useEffect, useState } from "react";
import {
	View,
	Linking,
	ActivityIndicator,
	StyleSheet,
} from "react-native";
import { CustomContext } from "../contexts/custom-context";
import api from "../services/api";
import { account } from "../utils/account";
import { apiSDK } from "../services/apiSDK";

export const CustomProvider = ({ children }: React.PropsWithChildren) => {
	const [loading, setLoading] = useState(true);
	const [store, setStore] = useState<string>("");
	const notificationToken = '';

	useEffect(() => {
		getStore();
		loginHidden();
		setTimeout(() => setLoading(false), 2000);
	}, []);

	const getStore = async () => {
		const urlbase = (await Linking.getInitialURL()) || "";
		const url = new URL(urlbase);
		const storeId = url.searchParams.get("store") as string;
		if (!storeId) return
		setStore(storeId);
	};

	const loginHidden = () => {
		apiSDK.session.login(account, notificationToken).then((response) => {
			const authToken = `Bearer ${response.data.token.token}`;
			api.defaults.headers.common["Authorization"] = authToken;
		});
	};

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			justifyContent: "center",
		},
	});

	return (
		<CustomContext.Provider
			value={{
				storeId: store,
				currentLatitude: "0",
				currentLongitude: "0",
				setStore: setStore
			}}
		>
			{loading ? (
				<View style={styles.container}>
					<ActivityIndicator
						size="large"
						color="#30374A"
					/>
				</View>
			) : children}
		</CustomContext.Provider>
	);
};
